import React, { Suspense, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { customEventEmitter, useWindowResize } from '@frontend-modules/frontend-utils';
import { ErrorBoundary } from 'containers/ErrorBoundary';
import { ErrorModal } from 'containers/ErrorModal';
import { PageLoader } from 'containers/PageLoader';
import { WithChatwoot } from 'containers/WithChatwoot';
import { NavigationMenu } from 'fsd/widgets/navigation';
import moment from 'moment';
import { Login } from 'pages/Login';
import { PasswordRecovery } from 'pages/PasswordRecovery';
import HttpInterceptor from 'src-new/components/auth/HttpInterceptor';
import { Modals } from 'src-new/components/layouts';
import { AlertModal } from 'src-new/containers/AlertModal';
import { getAlertsListAction } from 'store/actions/alerts';
import { setIsLoggedInAction } from 'store/actions/auth';
import { useAppSelector } from 'store/store';

import { PrivateRoute } from 'components/PrivateRoute';
import { getAccessToken } from 'utils/token';

import 'moment-timezone';
import 'moment/locale/ru';
import 'i18n';

import { reCaptchaKey } from './constants';

import 'react-quill/dist/quill.snow.css';
import 'root.scss';
import './App.scss';

moment.locale('ru');
moment.tz.load({
    version: '2021e',
    zones: [
        'Europe/Moscow|MMT MMT MST MDST MSD MSK +05 EET EEST MSK|-2u.h -2v.j -3v.j -4v.j -40 -30 -50 -20 -30 -40|012132345464575454545454545454545458754545454545454545454545454545454545454595|-2ag2u.h 2pyW.W 1bA0 11X0 GN0 1Hb0 c4v.j ik0 3DA0 dz0 15A0 c10 2q10 iM10 23CL0 1db0 1cN0 1db0 1cN0 1db0 1dd0 1cO0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1cM0 1cN0 IM0 rX0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 WM0 1qM0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 8Hz0|16e6',
    ],
    links: ['Europe/Moscow|W-SU'],
});
moment.tz.setDefault('Europe/Moscow');

const CnApp = cn('app-page');

const StudentsSchedulePage = React.lazy(() => import('pages/StudentsSchedule'));
const QuestionBankPage = React.lazy(() => import('pages/QuestionBankPage'));
const QuestionCreationPage = React.lazy(() => import('pages/QuestionCreationPage'));
const QuestionSearchPage = React.lazy(() => import('pages/QuestionSearchPage'));
const TrainingQuestionSearchPage = React.lazy(() => import('pages/QuestionSearchPage/TrainingQuestionSearchPage'));
const SchedulePage = React.lazy(() => import('pages/SchedulePage'));
const NewSubjectsPage = React.lazy(() => import('pages/NewSubjects'));
const Settings = React.lazy(() => import('pages/Settings'));
const StudentTasks = React.lazy(() => import('pages/StudentTasks'));
const SubjectCoursePage = React.lazy(() => import('pages/SubjectCourse'));
const Subjects = React.lazy(() => import('pages/Subjects'));
const HomeworkPage = React.lazy(() => import('pages/HomeworkPage'));
const LessonCard = React.lazy(() => import('pages/LessonCard'));
const MaterialsCreationPage = React.lazy(() => import('pages/MaterialsCreationPage'));
const Person = React.lazy(() => import('pages/Person'));
const AcademicPerformance = React.lazy(() => import('pages/AcademicPerformance'));
const CodifierPage = React.lazy(() => import('pages/CodifierPage'));
const Proctoring = React.lazy(() => import('pages/ProctoringPage'));
const ProctoringRoom = React.lazy(() => import('pages/ProctoringRoom'));
const Course = React.lazy(() => import('pages/Course'));
const CourseChangingPage = React.lazy(() => import('pages/CourseChangingPage'));
const CourseCreationPage = React.lazy(() => import('pages/CourseCreationPage'));
const CourseEditor = React.lazy(() => import('pages/CourseEditor'));
const Courses = React.lazy(() => import('pages/Courses'));
const StudentsPage = React.lazy(() => import('pages/Students'));
const EventRoomCreationPage = React.lazy(() => import('pages/EventRoomCreationPage'));
const ReportingPage = React.lazy(() => import('pages/ReportingPage'));
const SingleNewsPage = React.lazy(() => import('pages/SingleNewsPage'));
const DownloadAnyDeskPage = React.lazy(() => import('@fsd-pages/DownloadAnyDesk'));
const Dashboard = React.lazy(() => import('pages/Dashboard'));
const AuthenticationPage = React.lazy(() => import('pages/OAuth'));
const OnlineLessonsPage = React.lazy(() => import('pages/OnlineLessonsPage'));
const StatisticsMonitoring = React.lazy(() => import('@fsd-pages/StatisticsMonitoring'));
const LibraryPage = React.lazy(() => import('pages/LibraryPage'));
const EditLibraryPage = React.lazy(() => import('pages/EditLibraryPage'));
const NewsPage = React.lazy(() => import('pages/NewsPage'));
const NewsCreationPage = React.lazy(() => import('pages/NewsCreationPage'));
const PDFViewer = React.lazy(() => import('pages/PDFViewer'));
const GroupActionsPage = React.lazy(() => import('pages/GroupActionsPage'));

export const App: React.FC = () => {
    const dispatch = useDispatch();
    const { isMobile } = useWindowResize();
    const { isLoggedIn } = useAppSelector((state) => state.auth);
    const intervalId = useRef<any>(null);
    const [isReAuth, setReAuth] = useState(false);

    const getAlerts = () => {
        dispatch(getAlertsListAction());
    };

    const clearAlertInterval = () => {
        clearInterval(intervalId.current);
    };

    // отключаем алерты при перелогине
    const onReLoginStart = () => {
        setReAuth(true);
        clearAlertInterval?.();
    };

    useEffect(() => {
        setReAuth(!isLoggedIn);
        if (isLoggedIn) {
            getAlerts();
            intervalId.current = setInterval(() => getAlerts(), 60 * 1000);
        }

        return () => {
            clearAlertInterval();
        };
    }, [isLoggedIn]);

    // нужно для авторизации через login as
    useEffect(() => {
        const access = getAccessToken();
        if (access && !isLoggedIn) {
            dispatch(setIsLoggedInAction(true));
        }
    }, []);
    useEffect(() => {
        const observer = customEventEmitter.Instance;
        const changeFavicon = (data: string) => {
            const icoFavicon = document.getElementById('ico-favicon') as HTMLLinkElement;
            const svgFavicon = document.getElementById('svg-favicon') as HTMLLinkElement;
            const ico = `${data}_favicon.ico`;
            const svg = `${data}_favicon.svg`;
            if (icoFavicon) icoFavicon.href = ico;
            if (svgFavicon) svgFavicon.href = svg;
        };

        void observer.listen('chat_unread_messages', changeFavicon);

        return () => {
            void observer.removeListen('chat_unread_messages');
        };
    }, []);

    return (
        <DndProvider backend={HTML5Backend}>
            <HttpInterceptor onReLoginStart={onReLoginStart} />
            <WithChatwoot>
                <Router>
                    {process.env.REACT_APP_IS_DEV === 'dev' && (
                        <a href={'/storybook/index.html'} target="_blank" rel="noreferrer">
                            <div className="storybook-logo" />
                        </a>
                    )}
                    {/*isReAuth нужен, что бы не показывать ошибки при перелогине */}
                    {!isReAuth && <ErrorModal />}
                    <AlertModal />
                    <ErrorBoundary>
                        <div className={CnApp('container', { isMobile })}>
                            <NavigationMenu />
                            <div className={CnApp('content', { isMobile })}>
                                <Modals />
                                <Switch>
                                    <PrivateRoute path="/group-actions" isExact>
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <GroupActionsPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/news/edit/:newsId" isExact>
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <NewsCreationPage isEditPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/news/create" isExact>
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <NewsCreationPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/news/:newsId">
                                        <Suspense fallback={<PageLoader />}>
                                            <SingleNewsPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/news">
                                        <Suspense fallback={<PageLoader />}>
                                            <NewsPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/library/book-view/*" isExact>
                                        <Suspense fallback={<PageLoader />}>
                                            <PDFViewer />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/library/create" isExact>
                                        <Suspense fallback={<PageLoader />}>
                                            <EditLibraryPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/library/edit/:id" isExact>
                                        <Suspense fallback={<PageLoader />}>
                                            <EditLibraryPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/library" isExact>
                                        <Suspense fallback={<PageLoader />}>
                                            <LibraryPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/proctoring/edit/:roomId">
                                        <Suspense fallback={<PageLoader />}>
                                            <EventRoomCreationPage isEditPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute path="/proctoring/create">
                                        <Suspense fallback={<PageLoader />}>
                                            <EventRoomCreationPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/proctoring/event-room/:roomId">
                                        <Suspense fallback={<PageLoader />}>
                                            <ProctoringRoom />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact
                                        path={[
                                            '/reporting/:reportingType/:mode/:id',
                                            '/reporting/:reportingType/:mode',
                                            '/reporting/:reportingType',
                                            '/reporting',
                                        ]}
                                    >
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <ReportingPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/students">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <StudentsPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/settings">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <Settings />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/homework">
                                        <Suspense fallback={<PageLoader />}>
                                            <HomeworkPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/students-schedule">
                                        <Suspense fallback={<PageLoader />}>
                                            <StudentsSchedulePage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/online-lessons">
                                        <Suspense fallback={<PageLoader />}>
                                            <OnlineLessonsPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path={['/monitoring/:teacherId', '/monitoring']}>
                                        <Suspense fallback={<PageLoader />}>
                                            <StatisticsMonitoring />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/schedule">
                                        <Suspense fallback={<PageLoader />}>
                                            <SchedulePage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/courses/:courseId/course-editor"
                                    >
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <CourseEditor />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/materials/:editOrCreate/question-edit/:questionId"
                                    >
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionCreationPage isFromMaterials isEditPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/materials/:editOrCreate/question-create"
                                    >
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionCreationPage isFromMaterials />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/materials/:editOrCreate/fill-trainer/:materialIndex/question-create"
                                    >
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionCreationPage isFillTrainer />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path={[
                                            '/subjects/:subjectId/:lessonId/task/search-questions/:questionNum/create',
                                            '/subjects/:subjectId/:lessonId/task/edit/search-questions/:questionNum/:assignmentId/create',
                                        ]}
                                    >
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionCreationPage isSearchPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/task/search-questions/fill-trainer/:trainerId/create"
                                    >
                                        <Suspense fallback={<PageLoader />}>
                                            <TrainingQuestionSearchPage isFillTrainer />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/task/search-questions/fill-trainer/:trainerId/edit"
                                    >
                                        <Suspense fallback={<PageLoader />}>
                                            <TrainingQuestionSearchPage isFillTrainer isEditPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/task/search-questions/:questionNum"
                                    >
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionSearchPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/task/edit/search-questions/:questionNum/:assignmentId"
                                    >
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionSearchPage isEditPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/:lessonId/tasks/create">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <SubjectCoursePage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/tasks/edit/:assignmentId"
                                    >
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <SubjectCoursePage isEditPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/courses/create-course">
                                        <Suspense fallback={<PageLoader />}>
                                            <CourseCreationPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/courses/edit-courses">
                                        <Suspense fallback={<PageLoader />}>
                                            <CourseChangingPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/courses/:courseId">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <Course />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/anydesk">
                                        <Suspense fallback={<PageLoader />}>
                                            <DownloadAnyDeskPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path={[
                                            '/event-room/:roomId/lessons/:lessonId/tasks/students/:studentId/:taskId',
                                            '/subjects/:subjectId/lessons/:lessonId/tasks/students/:studentId/:taskId',
                                            '/subjects/:subjectId/lessons/:lessonId/tasks/students/:studentId',
                                        ]}
                                    >
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <StudentTasks />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/courses">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <Courses />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/:lessonId/materials/edit">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <MaterialsCreationPage isEditPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/materials/create"
                                    >
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <MaterialsCreationPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/additional_materials/edit"
                                    >
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <MaterialsCreationPage isEditPage isAdditional />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path="/subjects/:subjectId/:lessonId/additional_materials/create"
                                    >
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <MaterialsCreationPage isAdditional />
                                        </Suspense>
                                    </PrivateRoute>

                                    <PrivateRoute isExact={false} path="/subjects/:subjectId/:lessonId">
                                        <Suspense fallback={<PageLoader />}>
                                            <LessonCard />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute
                                        isExact={false}
                                        path={['/profile/:role/:personId/:tab', '/profile/:role/:personId']}
                                    >
                                        <Suspense fallback={<PageLoader />}>
                                            <Person />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/codifier/create-question/:lessonId">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionCreationPage isFromCodifier />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/codifier">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <CodifierPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/questions/edit/:questionId">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionCreationPage isEditPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact={false} path="/questions/create">
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionCreationPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/questions/select">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionBankPage isSelectQuestion />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/questions">
                                        <Suspense fallback={<PageLoader />}>
                                            <QuestionBankPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/subjects">
                                        <PageLoader />
                                        <Suspense fallback={<PageLoader />}>
                                            <Subjects />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/performance">
                                        <Suspense fallback={<PageLoader />}>
                                            <AcademicPerformance />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/proctoring">
                                        <Suspense fallback={<PageLoader />}>
                                            <Proctoring />
                                        </Suspense>
                                    </PrivateRoute>
                                    <Route exact path="/signin">
                                        <Suspense fallback={<PageLoader />}>
                                            <Login />
                                        </Suspense>
                                    </Route>
                                    <Route exact path="/restore">
                                        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey} language="ru">
                                            <PasswordRecovery />
                                        </GoogleReCaptchaProvider>
                                    </Route>
                                    <PrivateRoute isExact path="/o/authentication">
                                        <Suspense fallback={<PageLoader />}>
                                            <AuthenticationPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/new-subjects">
                                        <Suspense fallback={<PageLoader />}>
                                            <NewSubjectsPage />
                                        </Suspense>
                                    </PrivateRoute>
                                    <PrivateRoute isExact path="/">
                                        <Suspense fallback={<PageLoader />}>
                                            <PageLoader />
                                            <Dashboard />
                                        </Suspense>
                                    </PrivateRoute>
                                </Switch>
                            </div>
                        </div>
                    </ErrorBoundary>
                </Router>
            </WithChatwoot>
        </DndProvider>
    );
};
