let url = 'https://lms-backend.og1.tech';
const ssoUrl = process.env.REACT_APP_SSO_TEST_HOST;
const commonUrl = process.env.REACT_APP_COMMON_TEST_HOST;
const monitoringUrl = process.env.REACT_APP_MONITORING_TEST_HOST;
//let url = 'https://lms-backend-dev2.og1.ru';

if (process.env.REACT_APP_TEST_HOST) {
    url = process.env.REACT_APP_TEST_HOST;
} else if (window.location.href.includes('thmoon.tech') && !window.location.href.includes('dyn')) {
    if (window.location.href.includes('release')) {
        url = 'https://lms-backend.og1.tech';
    } else {
        url = 'https://lms-backend-staging.og1.ru/';
    }
}

let sentryDsn = 'https://f89adf2b6778442aa2c80423049129ca@sentry.og1.ru/17';

if (process.env.REACT_APP_SENTRY_DSN) {
    sentryDsn = process.env.REACT_APP_SENTRY_DSN;
} else if (window.location.href.includes('thmoon.tech') && !window.location.href.includes('dyn')) {
    if (window.location.href.includes('release')) {
        sentryDsn = 'https://f89adf2b6778442aa2c80423049129ca@sentry.og1.ru/17';
    } else {
        sentryDsn = 'https://f42b24955aac4f4c94a508214250c272@sentry.og1.ru/18';
    }
}

export const isLocalHost = window.location.href.includes('localhost');

export const host = url;
export const ssoHost = ssoUrl;
export const commonHost = commonUrl;
export const dsn = sentryDsn;
export const cookiesDomain = isLocalHost ? undefined : host.includes('tech') ? 'og1.tech' : 'og1.ru';
export const monitoringHost = monitoringUrl;
